<template>
  <div class="mt-4">
    <div class="sm:flex sm:items-start">
      <div
        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
      >
        <svg
          class="h-6 w-6 text-red-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
          Delete {{ newButton.label }}(s)
        </h3>
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            Are you sure you want to delete {{ newButton.label }}(s)? All of your data will be
            permanently removed from our servers forever. This action cannot be undone.
          </p>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        class="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-3 py-1 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
        @click.prevent="modal.callback ? modal.callback() : destroy()"
      >
        Delete
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded border border-gray-300 shadow-sm px-3 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
        @click.prevent="setModal({ show: false })"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'

export default {
  name: 'Delete',
  props: [],
  data() {
    return {}
  },
  methods: {
    destroy() {
      const endpoint = `${this.newButton.endpoint}?ids=${this.modal.data.id}`

      http.delete(`${endpoint}`).then(() => {
        this.$bus.$emit(this.modal.notifier)
        this.setModal({ show: false })
      })
    },
  },
}
</script>

<style></style>
